<template>
  <div style="margin: 2%"><p style="line-height:19pt; margin:0pt; text-align:center"><span
      style="font-family:DengXian; font-size:14pt">用户协议</span><span
      style="font-family:DengXian; font-size:14pt">及隐</span><span
      style="font-family:DengXian; font-size:14pt">私</span><span
      style="font-family:DengXian; font-size:14pt">政策</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt; text-align:center"><span
        style="font-family:DengXian; font-size:14pt">《</span><span
        style="font-family:DengXian; font-size:14pt">用户协议</span><span
        style="font-family:DengXian; font-size:14pt">》</span></p>
    <p style="line-height:19pt; margin:0pt; text-align:center"><span
        style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第一条【完全了解】</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">请您（以下亦称</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style="font-family:DengXian; font-size:14pt; font-weight:bold">用户</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">）仔细阅读本协议全部条款，并确认您已完全了解本协议之规定。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">未成年人应当在监护人陪同和指导下阅读本协议，并在使用本协议项下服务前取得监护人的同意。</span></p>
    <p style="line-height:19pt; margin:0pt; text-indent:28pt"><span style="font-family:DengXian; font-size:14pt">请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束，本协议将构成您与</span><span
        style=" font-family:DengXian; font-size:14pt">{{company}}</span><span
        style="font-family:DengXian; font-size:14pt">（及关联方）与其主办的</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:DengXian; font-size:14pt">小程序</span><span
        style="font-family:DengXian; font-size:14pt">（</span><span
        style="font-family:DengXian; font-size:14pt">合称为</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">）之间直接有约束力的法律文件。</span></p>
    <p style="line-height:19pt; margin:0pt; text-indent:28pt"><span
        style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第二条【充分授权】</span></p>
    <p style="line-height:19pt; margin:0pt; text-indent:28pt"><span style="font-family:DengXian; font-size:14pt">请确认您依照中华人民共和国法律（为本协议之目的，含行政法规、部门规章、规范性文件，下同）、及您所在地之法律、及您自愿或应当接收其管辖之法律（以下合称</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style="font-family:DengXian; font-size:14pt; font-weight:bold">法律</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">），有权签署</span><span
        style="font-family:DengXian; font-size:14pt">本协议</span><span style="font-family:DengXian; font-size:14pt">并同意接受本协议</span><span
        style="font-family:DengXian; font-size:14pt">之</span><span style="font-family:DengXian; font-size:14pt">约束。如果您代表您的雇主或单位，请在签署前，确认并保证您已获得签署本协议的充分授权。</span>
    </p>
    <p style="line-height:19pt; margin:0pt; text-indent:28pt"><span
        style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第三条【协议变更】</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">本协议如果变更，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">将尽可能提前在</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">发布通知。但您应了解并同意，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">有可能未经事先通知，而修改或变更本协议的全部或部分内容，您也应随时关注本协议是否有修改或变更之情形。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">如果本协议修改或变更，而您不能接受修改或变更后的内容，您可以停止使用</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">。如果您继续使用</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">，则表明您完全接受并愿意遵守修改或变更后的协议。</span></p>
    <p style="line-height:19pt; margin:0pt; text-indent:28pt"><span style="font-family:DengXian; font-size:14pt">无论您停止使用或继续使用</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">，您都承诺不因本协议修改或变更给您造成的损失或不便而向</span><span
        style=" font-family:DengXian; font-size:14pt">{{company}}</span><span
        style="font-family:DengXian; font-size:14pt">（及关联方）或</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">要求赔偿或补偿。</span></p>
    <p style="line-height:19pt; margin:0pt; text-indent:28pt"><span
        style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第四条【服务方式】</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">主要是一家网络服务提供者，可针对不同传播渠道用户为其提供内容服务，前述传播渠道包括但不限于：</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">1</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">通过</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的服务</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt"> (</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">2</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">提供的可以使用的其他传播渠道。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">除免费服务外，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">可能提供部分收费服务。您可以通过付费方式购买收费服务，具体方式为</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">:</span><span
        style="font-family:DengXian; font-size:14pt">通过网上银行或其他</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">提供的付费途径支付一定数额的人民币，从而获得收费服务使用权限。对于收费服务，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">会在您使用之前给予明确的提示，只有根据提示确认其同意按照前述支付方式支付费用并完成了支付行为，才能使用该等收费服务。支付行为的完成以银行或第三方支付平台生成</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style="font-family:DengXian; font-size:14pt">支付已完成</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的确认通知为准。</span></p>
    <p style="line-height:19pt; margin:0pt; text-indent:28pt"><span
        style="font-family:DengXian; font-size:14pt">您应了解</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">可能对该等服务（含收费服务）进行更改、更新或提高，并承诺不因</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">更改、更新或提高其服务给您造成的损失或不便而向</span><span
        style=" font-family:DengXian; font-size:14pt">{{company}}</span><span
        style="font-family:DengXian; font-size:14pt">（及关联方）或</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">要求赔偿或补偿。</span></p>
    <p style="line-height:19pt; margin:0pt; text-indent:28pt"><span
        style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第五条【善意使用】</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您同意，遵守法律及本协议规定，秉承善意使用</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的原则，</span><span
        style="font-family:DengXian; font-size:14pt">且保证：</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">1</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">)</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">不会利用</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">进行非法活动、或进行侵犯他人权利或损害他人利益的活动；不会以非法方式获取或利用</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">其他用户的信息。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">2</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">不会以技术方式攻击或破坏或改变</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:DengXian; font-size:14pt">款</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的部分或全部、或干扰其运行；不会以非法方式获取或使用</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的任何软件、代码或其他技术或商业信息；不会对</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">运行的任何程序进行反向工程、反向编译、反向汇编或改写。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">3</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">不会未经许可使用</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的网站名称、公司名称、商标、商业标识、网页版式或内容、或其他由</span><span
        style=" font-family:DengXian; font-size:14pt">{{company}}</span><span
        style="font-family:DengXian; font-size:14pt">（及关联方）或</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">享有知识产权或权利的信息或资料；不会侵犯</span><span
        style=" font-family:DengXian; font-size:14pt">{{company}}</span><span
        style="font-family:DengXian; font-size:14pt">（及关联方）或</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的商标权、著作权、专利权、其他知识产权或其他合法权利或权益。不会以任何方式贬损</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的商业声誉。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">4</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">不会利用</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">进行其他违背公序良俗的活动。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您使用</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的记录，可能被保存作为对您不利的证据。</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">也可能将您违反法律或侵犯第三方权利或权益的记录报告给行政主管部门、司法机关。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第六条</span><span
        style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">【注册账号】</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您需要在</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">注册一个账号，并按照</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的要求为该账号设置相关操作，才可以使用该账号登录并使用</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">。您可依照</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的要求修改您的账号信息。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您注册账号时，应按照</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的提示及要求填写或提供资料、信息，并确保用户身份及信息的真实性、正确性及完整性；如果资料发生变化，您应及时更改。</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您同意并承诺：</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">1</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">不故意冒用他人信息为您注册帐号；</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">2</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">不未经他人合法授权以他人名义注册帐号；</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">3</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">不使用色情、暴力或侮辱、诽谤他人等违反公序良俗的词语注册帐号。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">如您违反前述规定，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">有权随时拒绝您使用该账号，或者限制您使用，或者注销该账号。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第七条</span><span
        style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">【账号使用】</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">在您完成账号注册后，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">将向您提供帐号及相关信息，此后，您应自行保管及维持帐号的安全。您应自行使用该账号，并对任何人利用您的帐号所进行的活动负完全的责任。</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您应了解，在账号和密码匹配时，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">无法对非法或未经您授权使用您帐号及密码的行为作出甄别，因此，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">对任何使用您账号登录并使用</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的行为不承担任何责任。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您同意并承诺：</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">1</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">当您的帐号或密码遭到未获授权的使用，或者发生其他任何安全问题时，您会立即有效通知到</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">；</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">2</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">当您每次上网或使用其他服务完毕后，会将有关帐号等安全退出</span><span
        style="font-family:DengXian; font-size:14pt">。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">有权根据自己的判定，在怀疑账号被不当使用时，拒绝账号使用或限制账号使用或注销该账号。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">如您连续</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">180</span><span
        style="font-family:DengXian; font-size:14pt">天未以账号登录</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">，则</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">有权根据自己的判定，注销该账号且无需事先向您发送通知。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第八条</span><span
        style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">【个人信息】</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">可能需要搜集能识别用户身份的个人信息以便可以在必要时联系用户，或为用户提供更好的使用体验。</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style="font-family:DengXian; font-size:14pt">个人信息</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">包</span><span style="font-family:DengXian; font-size:14pt">括但不限于用户的姓名、性别、年龄、出生日期、联系电话、身份证号、地址、受教育情况、公司情况、所属行业、兴趣爱好、所处位置信息、个人说明、手机软硬件信</span><span
        style="font-family:DengXian; font-size:14pt">息</span><span
        style="font-family:DengXian; font-size:14pt">、手机识别码等。</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">承诺，不以非法方式披露您依照</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">规定的方式标明应予保密的用户个人信息。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您了解并同意，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">可依照法律或司法、行政机关的强制性命令对第三方披露您的个人信息且无需事先向您发出通知。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您了解并同意，当您购买在</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">列出的商品或服务时，您的信息将被提供前述商品或服务商家收集，您应自行承担此类信息披露的责任。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您了解并同意，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">在下述情形下可利用您的个人信息：</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">1</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">在紧急情况下，为维护用户及公众的权益。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">2</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">为维护</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的著作权、商标权、专利权及其他任何合法权利或权益。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">3</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">在进行促销或抽奖时，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">可能会与赞助商共享用户的个人信息，在这些情况下</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">会在发送用户信息之前进行提示，并且用户可以通过明确表示不参与活动而终止传送过程。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">4</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">为获取第三方数据而将用户信息与第三方数据匹配。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">5</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">将用户数据用于统计，以便向未来的合作伙伴、广告商及其他第三方以及为了其他合法目的而描述</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style="font-family:DengXian; font-size:14pt">拍同款</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的服务。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第九条</span><span
        style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">【第三方内容】</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您了解并同意，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">可能存在大量由第三方享有权利的内容；您不会违反法律而传播任何您不明确享有合法权利的内容。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">可以随时依其自身判断指定您不应传播的第三方内容。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您应自行对从</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">获悉或获取的第三方内容进行分析、甄别、判断、依法使用并合理预防其风险。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">不保证您接受的任何第三方内容具有合法性或真实性或准确性或安全性，也不保证其构成任何可靠的或全面的知识系统，也不对因存储或传送第三方内容过程中产生的延误、错误、遗漏、不准确、或由此产生的任何直接或间接损害，向您赔偿或补偿或承担法律责任。</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第十条</span><span
        style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">【链接】</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">为方便您使用，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">可能会提供第三方国际互联网网站或资源链接。您应了解，除非另有声明，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">无法对第三方网站服务进行控制。您因下载或传播或使用或依赖上述网站或资源所生的损失或损害，应由您自行承担全部责任。</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第十一条</span><span
        style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">【容忍义务】</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您应了解，基于不同用户的个人特征和喜好，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">可能存在一些让您不快、厌恶或难以接受的内容。您承诺，不就前述内容追究</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">或第三方的任何责任。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第十二条</span><span
        style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">【</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的商业活动】</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您了解并接受，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">为维护其运营所经营，可能进行下述商业活动：</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">1</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">通过电子邮件、客户端、网页或其他合法方式向用户发送商品促销或其他相关商业信息。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">2</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">通过增值服务系统或其他方式向用户发送的相关服务信息或其他信息，其他信息包括但不限于通知信息、宣传信息、广告信息等。</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">第十三条</span><span
        style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">【非商业使用】</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您同意，仅以非商业目的使用</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">；不对</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的任何部分或全部进行商业性质利用、复制、拷贝、出售、调查、广告，或将</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">用于其他任何商业目的或商业性质的活动；但</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">与您另行签订有协议或</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">另行指定可供您使用的特定商业服务除外。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第十四条</span><span
        style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">【网络运行】</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">尽可能保证其稳定运行。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您应理解并同意，因法律、政策、技术、经济、管理的原因，除非您和</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">另有约定，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">不会因以下情形出现而对您承担责任：</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">1</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">无法使用或中断使用或无法完全适合用户的使用要求。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">2</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">受到干扰，无法及时、安全、可靠运行，或出现任何错误。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">3</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">经由</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">取得的任何产品、服务（含收费服务）或其他材料不符合您的期望。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">4</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">)</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">用户资料遭到未经授权的使用或修改。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">不对您在本协议项下服务中相关数据的删除或储存失败负责。</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">可以根据实际情况自行决定用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。如您停止使用</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">，或</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">所提供的服务终止或中止，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">可以从服务器上永久地删除用户的数据。</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">没有义务向用户返还任何数据。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第十五条</span><span
        style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">【服务终止】</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">本协议或本协议项下</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">所提供的服务（含收费服务）可在下述情形下部分或全部中止或终止：</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">1</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">因法律规定，或</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">服从行政命令或司法</span><span
        style="font-family:DengXian; font-size:14pt">裁判</span><span
        style="font-family:DengXian; font-size:14pt">的要求。</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">2</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">)</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">用户违反本协议。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">3</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">)</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt"> “</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">认为应予终止的其他情形。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">在一般情形下，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">会提前按照您提供的联系方式以电子邮件或短信或其他电子方式通知您，服务将中止或终止。您应了解并同意，在紧急情况或特殊下，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">可不经通知即中止或终止服务。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">在本协议或本协议项下</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">提供的服务（含收费服务）中止或终止时，</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">有权</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">:</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt"> (</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">1</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">拒绝用户登录</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">；</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">2</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">删除用户信息；</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">(</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">3</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">) </span><span
        style="font-family:DengXian; font-size:14pt">删除用户购买的内容。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您同意，不因本协议或本协议项下</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">所提供的服务中止或终止，而要求</span><span
        style=" font-family:DengXian; font-size:14pt">{{company}}</span><span
        style="font-family:DengXian; font-size:14pt">（及关联方）或</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">向您作出赔偿或补偿或承担任何其他责任。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第十六条</span><span
        style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">【法律适用】</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您在使用</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">时，应遵守中华人民共和国法律、及您所在地之法律、及您自愿或应当接收其管辖之法律。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">本协议的解释及履行应按照中华人民共和国法律进行。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第十七条</span><span
        style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">【法律责任】</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"></span><span
        style="font-family:DengXian; font-size:14pt">您应就所上传的内容承担全部法律责任；无论前述责任是因侵犯第三方权利所引起的、或因您违反与第三方或本协议引起的、或因您违反法律引起的；前述责任包括对</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style="font-family:DengXian; font-size:14pt">拍同款</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">或第三方所承担的民事责任、或行政机关要求承担的行政责任或刑事责任。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您同意，如因您违反法律规定或本协议规定给</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">造成损失，您将充分赔偿</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">所遭受的损失、包括其直接损失、间接损失、预期利益损失等一切损失。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"></span><span
        style="font-family:DengXian; font-size:14pt">您承诺，如因第三方向您主张权利而导致您承担责任，或您承担行政责任或刑事责任的，您不以此为理由追究</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">的责任。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"></span><span
        style="font-family:DengXian; font-size:14pt">如您应向</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">承担责任，前述责任不因本协议或本协议项下的服务被终止而免除。</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第十八条</span><span
        style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">【相关协议】</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"></span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">可能不时发布针对用户的相关协议，并可能将该相关协议作为对本协议的补充或修改而将其内容作为本协议的一部分。请您及时关注并阅读相关协议。</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt">第十九条</span><span
        style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">【争议解决】</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:DengXian; font-size:14pt"> </span><span
        style="font-family:DengXian; font-size:14pt">您和</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">“</span><span
        style=" font-family:DengXian; font-size:14pt">{{subject}}</span><span
        style="font-family:'Helvetica Neue'; font-size:14pt">”</span><span
        style="font-family:DengXian; font-size:14pt">均同意，因本协议解释或执行引起的任何争议，双方应首先友好协商解决。协商不成时，则任一方均可将争议提交杭州仲裁委员会依据其届时有效的仲裁规则以仲裁方式解决。仲裁地点为杭州，仲裁语言为中文。仲裁裁决为终局的，对各方均有法律约束力。</span>
    </p>
    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
        style="font-family:DengXian; font-size:14pt"></span><span
        style="font-family:DengXian; font-size:14pt">请您再次确认您已全部阅读并充分理解上述协议。</span></p>
    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span style="font-family:DengXian; font-size:12pt">&#xa0;</span>
    </p>
    <p style="line-height:19pt; margin:0pt"><span style="font-family:'Helvetica Neue'; font-size:14pt">&#xa0;</span></p>
    <p style="line-height:19pt; margin:0pt; text-align:center"><span
        style="font-family:DengXian; font-size:14pt">《</span><span
        style="font-family:DengXian; font-size:14pt">用户</span><span
        style="font-family:DengXian; font-size:14pt">隐私</span><span
        style="font-family:DengXian; font-size:14pt">政</span><span
        style="font-family:DengXian; font-size:14pt">策》</span></p>
    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span style="font-family:DengXian; font-size:12pt">&#xa0;</span>
    </p>
    <p style="background-color:#ffffff; line-height:16.5pt; margin:0pt"><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">（以下简称“</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">”）非常重视用户的隐私权，具体细则如下：</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="font-family:'PingFang SC'; font-size:14pt; font-weight:bold">一、隐私权政策适用范围</span></p>
    <p style="background-color:#ffffff; line-height:16.5pt; margin:0pt"><span
        style="font-family:'PingFang SC'; font-size:13.5pt">包括</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">如何处理用户在登录其</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">平台</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">和服务器时留下的个人身份识别信息，以及与商业伙伴共享的其他身份识别信息。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="font-family:'PingFang SC'; font-size:14pt; font-weight:bold">二、信息收集和使用</span></p>
    <p style="background-color:#ffffff; line-height:16.5pt; margin:0pt"><span
        style="font-family:'PingFang SC'; font-size:13.5pt">在你注册</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">帐户，使用其他</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">产品或服务，访问</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">程</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">序时</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">, 或参加促销和有奖游戏时，</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">会收集你的个人身份识别资料。</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">也会收集来自商业伙伴的身份识别资料。</span><br/><span
        style="font-family:'PingFang SC'; font-size:13.5pt">当你注册</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">帐户时,</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt"> </span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">会问及你的个人信息。只要你在</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">成功注册并登录服务器，</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">将可以识别你。</span><br/><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">会自动接收并记录你的浏览器和服务器日志上的信息，其中包括你的 IP 地址,</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt"> </span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt"> cookie 中的信息以及你需求的</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">页</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">面</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">记录。</span><br/><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">会将这些资料用于：改进为你提供的</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">业务</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">内容，满足你对某种产品的需求或通知你最新产品信息。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="font-family:'PingFang SC'; font-size:14pt; font-weight:bold">三、信息公开与共享</span></p>
    <p style="background-color:#ffffff; line-height:16.5pt; margin:0pt"><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">不会将你的个人识别信息出租或出售给任何人。</span><br/><span
        style="font-family:'PingFang SC'; font-size:13.5pt">以下情况除外：</span><br/><span
        style="font-family:'PingFang SC'; font-size:13.5pt">* 你同意让第三方共享资料；</span><br/><span
        style="font-family:'PingFang SC'; font-size:13.5pt">* 只有透露你的个人资料，才能提供你所要求的产品和服务；</span><br/><span
        style="font-family:'PingFang SC'; font-size:13.5pt">* </span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">需要向代表</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">提供产品或服务的公司提供资料（除非</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">另行通知你，否则这些公司无权使用你的身份识别资料）；</span><br/><span
        style="font-family:'PingFang SC'; font-size:13.5pt">* </span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">需要听从法庭传票、法律命令或遵循法律程序；</span><br/><span
        style="font-family:'PingFang SC'; font-size:13.5pt">* </span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">发现你违反了</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">拍同款</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">服务条款或任何其他产品及服务的使用规定。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="font-family:'PingFang SC'; font-size:14pt; font-weight:bold">四、Cookie</span></p>
    <p style="background-color:#ffffff; line-height:16.5pt; margin:0pt"><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">会在你的电脑上设定或取用Cookie。</span><br/><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">允许那些在</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">网页上发布广告的公司到用户电脑上设定或取用Cookie 。其他公司将按其自订的隐私权政策而非本政策使用Cookie，他们不能取用</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">的Cookie。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="font-family:'PingFang SC'; font-size:14pt; font-weight:bold">五、编辑个人帐户资料的权限</span></p>
    <p style="background-color:#ffffff; line-height:16.5pt; margin:0pt"><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">赋予你在任何时候编辑</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">帐户信息和喜好的权利。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="font-family:'PingFang SC'; font-size:14pt; font-weight:bold">六、安全保障</span></p>
    <p style="background-color:#ffffff; line-height:16.5pt; margin:0pt"><span
        style="font-family:'PingFang SC'; font-size:13.5pt">你的</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">帐户具有取回密码功能，以确保你的隐私和信息安全。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="font-family:'PingFang SC'; font-size:14pt; font-weight:bold">七、隐私权政策的修订</span></p>
    <p style="background-color:#ffffff; line-height:16.5pt; margin:0pt"><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">时常会对隐私权政策进行修改。如果在使用用户个人信息政策方面有大幅度修改时，</span><span
        style=" font-family:'PingFang SC'; font-size:13.5pt">{{subject}}</span><span
        style="font-family:'PingFang SC'; font-size:13.5pt">会在网页中显眼的位置贴出相关规定以便及时通知用户。</span></p>
    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span style="font-family:DengXian; font-size:12pt">&#xa0;</span>
    </p></div>
</template>

<script>
import { userInfo } from '../api/agreement'

export default {
  name: "Agreement",
  data(){
    return{
      company:"杭州知视云科技有限公司",
      subject:"视客联盟"
    }
  },
  methods:{
    initData() {
      userInfo({}).then((res) => {
        console.log(JSON.stringify(res))
        this.subject = res.data.authorizerApp.nick_name
        this.company = res.data.authorizerApp.principal_name
      });
    },
  },
  mounted(){
    let query = this.$route.query;
    console.log(JSON.stringify(query))
    let SKLM_WX_USER_TOKEN = this.$route.query.sklmWxUserToken
    localStorage.setItem("SKLM_WX_USER_TOKEN",SKLM_WX_USER_TOKEN)
    this.initData();
  },
}
</script>

<style scoped>

</style>
